
export class State {

  static setIndex(index) {
    State.index = index;
  }

  static getIndex() {
    return State.index;
  }
}

State.index = 0;