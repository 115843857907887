import React from 'react';
import './App.scss';
import './styles/Shared.scss';
import {BrowserRouter as Router, Navigate, Route, Routes} from "react-router-dom";
import ContactPage from "./pages/ContactPage";
import img from "./res/imgs/critical-whiteness.jpg";
import {Helmet} from "react-helmet/es/Helmet";
import {Navigation} from "./components/Navigation";
import {Layout} from "./components/Layout";
import Vaterland from "./works/Vaterland";
import DerWertDerWahrheit from "./works/DerWertDerWahrheit";
import ProtestSelfie from "./works/ProtestSelfie";
import SchlimmstenfallsEineUtopie from "./works/SchlimmstenfallsEineUtopie";
import SpurenImSand from "./works/SpurenImSand";
import CriticalWhiteness from "./works/CriticalWhiteness";
import Solange from "./works/Solange";
import Negritude from "./works/Negritude";
import HeimatSchreiben from "./works/HeimatSchreiben";
import {WorksPage} from "./pages/WorksPage";

const CarouselIndex = React.createContext(0);

export default function App({children}) {

  CarouselIndex._currentValue = 2;

  return (
    <Router>
      <div className="App">
        <Helmet>
          <title>Michelle Akanji</title>
          <meta name='description'
                content='Reportagen, Features und Essays von Michelle Akanji, Kulturpublizistin in Zürich. '/>
          <meta property="og:image" content={img}/>
        </Helmet>

        <Navigation/>

        <CarouselIndex.Provider value={1}>
          <Layout children={children}>

            <Routes>
              <Route path='works' element={<WorksPage/>}/>
              <Route path='vaterland' element={<Vaterland/>}/>
              <Route path='der-wert-der-wahrheit' element={<DerWertDerWahrheit/>}/>
              <Route path='heimat-schreiben' element={<HeimatSchreiben/>}/>
              <Route path='protest-selfie' element={<ProtestSelfie/>}/>
              <Route path='schlimmstenfalls-eine-utopie' element={<SchlimmstenfallsEineUtopie/>}/>
              <Route path='spuren-im-sand' element={<SpurenImSand/>}/>
              <Route path='critical-whiteness' element={<CriticalWhiteness/>}/>
              <Route path='solange' element={<Solange/>}/>
              <Route path='w-wie-negritude' element={<Negritude/>}/>
              <Route path='contact' element={<ContactPage/>}/>

              <Route index element={<Navigate replace to="works" />} />
            </Routes>

          </Layout>
        </CarouselIndex.Provider>
      </div>
    </Router>
  );
}
